.auth-page {
    font-size: 14px;
    position: relative;
    width: 100vw;
    height: 100vh;
    background: #44596c11;
    font-weight: 500;
    color: $dark;
    margin: 0 -15px; /* undo the padding of container-fluid */
    padding:15px;
    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
    .header {
      padding: 30px;
      text-align:center;
    }
    .container {
      max-width: 800px;
      width: calc(100% - 40px);
      background-color: $white;
      padding: 2rem;
      margin: 0 1rem;
      border-radius:$border-radius;
      @include media-breakpoint-up(md) {
        margin: 0 2rem;
      }
      h1 {
        margin-bottom:2rem;
        text-align:center;
        font-weight:800;
        color:$primary;
        font-family:inherit;
      }
      .btn-lg {
        font-weight: 600;
        font-size: 1.5rem;
        padding: 1rem;
        margin-top:1rem;
      }
      a {
        color: $secondary;
        &:hover, &:focus {
          color: darken($secondary, 20%);
        }
      }
      @mixin placeholder {
          .form-control::-webkit-input-placeholder {
              @content
          }
          .form-control:-moz-placeholder {
              @content
          }
          .form-control::-moz-placeholder {
              @content
          }
          .form-control:-ms-input-placeholder {
              @content
          }
      }
      @include placeholder {
          color: rgba($dark, 0.6);
      }
    }
  }