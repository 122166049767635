.ag-cell-h-full {
  height: 40px;
}

.ag-edit-select {
  padding-left: 14px;
  padding-right: 14px;
  height: 40px;
  border: none;
  width: 100%;
}

@each $color, $value in $theme-colors {
  .ag-row-#{$color} {
    background: theme-color-level($value, $alert-bg-level) !important;
    color: theme-color-level($value, $alert-color-level) !important;
  }
}

@each $color, $value in $theme-colors {
  .ag-cell-#{$color} {
    background: theme-color-level($value, $alert-bg-level) !important;
    color: theme-color-level($value, $alert-color-level) !important;
  }
}
