.btn.btn-with-icon {
  display: flex;
  align-items: center;
  font-weight: 500;
  width: 100%;
  border-radius: 0.3rem;

  .download-icon {
    background: $white;
    border-radius: 0.3rem;
    margin: -0.2rem 1rem -0.2rem -0.7rem;
    height: 45px;
    position: relative;
    flex: 0 0 50px;

    img {
      max-width: 65%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &.width-auto {
    width: auto;
  }
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
