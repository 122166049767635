.auth-message {
  padding: 20px;
  font-size: 1.5rem;
  color: $white;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .auth-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.cls-3 {
  fill: #1ef3fd;
}

.cls-4 {
  fill: #44596c;
}

.c-body-full {
  height: 100vh;
  overflow: auto;

  @media (min-width: 768px) {
    height: calc(100vh - 168px);
    display: flex;
    flex-direction: column;
  }
}
