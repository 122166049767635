$blue: #44596c;
$lightBlue: #1ef3fd;

$darkGray: #4a4949;
$gray: #d8d9d9;

$primary: $blue;
$secondary: $lightBlue;
$light: $gray;
$dark: $darkGray;
$white: #ffffff;

/** Backgrounds **/
$bg-blue: $blue;

$modal-content-border-radius: 0.5rem;

$input-disabled-bg: #f3f5f7;
