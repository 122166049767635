.search-form-container {
  form {
    line-height: 1.25;

    small {
      margin-bottom: 4px;
    }
  }
}

.searching-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  .loading-container {
    color: $primary;
    background: $white;
    border-radius: 10px;
    padding: 1.5em 2em;
  }
}

.admin-buttons {
  .btn {
    margin: 1rem 0;
    flex: 1 0 320px;
  }
}

label.required:after {
  content: ' *';
  color: $danger;
}

@media (min-width: 480px) {
  .admin-buttons {
    .btn {
      width: 320px;
    }
  }
}

@media (min-width: 768px) {
  .admin-buttons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .admin-buttons-band {
      width: 700px;
      display: flex;

      .btn {
        margin: 0 1rem;
        width: 100%;
      }
    }
  }
}

/*** Override checkboxes and radio buttons ***/
.form-check {
  padding: 0;

  [type='checkbox'],
  [type='radio'] {
    &:not(:checked),
    &:checked {
      position: absolute;
      left: -9999px;

      ~ label {
        position: relative;
        padding-left: 1.95rem;
        cursor: pointer;
        line-height: 1.25rem;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 1.25rem;
          height: 1.25rem;
          border: 1px solid $border-color;
          background: #fff;
          border-radius: $border-radius;
          transition: all 0.2s;
        }

        &:after {
          content: '\f00c';
          position: absolute;
          top: calc(0.25rem + 2px);
          bottom: 0;
          margin: auto;
          left: 0.125rem;
          width: 1rem;
          text-align: center;
          font-size: 0.8rem;
          line-height: 1;
          height: 1rem;
          color: $white;
          transition: all 0.2s;
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
        }

        &:hover:before {
          border-color: $primary !important;
        }
      }

      &:disabled {
        ~ label {
          cursor: default;
        }
      }
      /* accessibility */
      &:focus ~ label:before {
        border: 1px dotted $primary;
      }
    }
  }
  /* checked mark aspect changes */
  [type='checkbox'],
  [type='radio'] {
    &:not(:checked) ~ label:after {
      opacity: 0;
      transform: scale(0);
    }

    &:checked ~ label:after {
      opacity: 1;
      transform: scale(1);
    }

    &:checked ~ label:before {
      background: $primary;
      border: 1px solid $primary !important;
    }

    &:disabled:checked ~ label:before {
      opacity: 0.6;
    }

    &:disabled:not(:checked) ~ label:hover:before {
      border-color: $border-color !important;
    }

    &:disabled:not(:checked) ~ label:before {
      background-color: $input-disabled-bg;
    }
  }

  [type='radio'] {
    &:not(:checked),
    &:checked {
      ~ label:before {
        border-radius: 50%;
      }

      ~ label:after {
        content: '';
        left: 0.3125rem;
        font-size: 0.75rem;
        top: 0px;
        background: $white;
        height: 0.625rem;
        width: 0.625rem;
        border-radius: 50%;
      }
    }
  }

  label [type='checkbox'],
  label [type='radio'] {
    position: static !important;
    margin-left: 0;
  }
}

.form-check-inline:last-of-type {
  margin-right: 0;
}

html:not([dir='rtl']) .form-check {
  padding-left: 0; /* CoreUI fix */
}
